.showContent {
  /* animation: show-content;
  animation-duration: 1s;
  animation-timing-function: ease; */

  /* animation: show-content 3s ease-in;
  animation-fill-mode: forwards; */

  /* transition: all 1s cubic-bezier(0.075, 0.82, 0); */

  transition: max-height 3s ease;
  max-height: none;
  overflow: auto;
}

@keyframes show-content {
  0% {
    max-height: 0;
    /* overflow: hidden; */
  }
  100% {
    max-height: 100%;
    overflow: auto;
  }
}

.collapseContent {
  /* animation: collapse-content;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards; */

  transition: max-height 3s ease;
  max-height: 0;
  overflow: hidden;
}

@keyframes collapse-content {
  0% {
    /* max-height: 100%; */
    /* overflow: auto; */
  }
  100% {
    max-height: 0;
    overflow: hidden;
  }
}
