:root {
  /* --yellow: #feb60a;
  --red: #ff0062;
  --blue: #00dbf9;
  --violet: #da00f7; */
  --yellow: #003;
  --red: #003;
  --blue: #003;
  --violet: #003;
}

.dotloader__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dotloader__container > div {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  margin: 3px;
  background-image: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  animation: bounce 1.5s 0.5s linear infinite;
}
.dotloader__yellow {
  background-color: var(--yellow);
}

.dotloader__red {
  background-color: var(--red);
  animation-delay: 0.1s;
}

.dotloader__blue {
  background-color: var(--blue);
  animation-delay: 0.2s;
}

.dotloader__violet {
  background-color: var(--violet);
  animation-delay: 0.3s;
}

@keyframes bounce {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(1.2);
  }
}
