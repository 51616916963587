.spinLoader {
  animation: spin 1s linear infinite;
  height: 10px;
  width: 10px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  margin: -5px; */
  /* --primary: rgba(18, 16, 99, 0.8); */
  --primary: rgba(18, 16, 99, 0.8);
  --secondary: #565584;
  /* background-color: var(--p); */
}

@keyframes spin {
  0% {
    box-shadow: 0px -30px var(--primary), 10px -30px var(--primary),
      20px -20px var(--primary), 30px -10px var(--primary),
      30px 0px var(--primary), 30px 10px var(--primary),
      20px 20px var(--primary), 10px 30px var(--primary), 0px 30px transparent,
      -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }
  6.25% {
    box-shadow: 0px -30px transparent, 10px -30px var(--primary),
      20px -20px var(--primary), 30px -10px var(--primary),
      30px 0px var(--primary), 30px 10px var(--primary),
      20px 20px var(--primary), 10px 30px var(--primary),
      0px 30px var(--primary), -10px 30px transparent, -20px 20px transparent,
      -30px 10px transparent, -30px 0px transparent, -30px -10px transparent,
      -20px -20px transparent, -10px -30px transparent;
  }
  12.5% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px var(--primary), 30px -10px var(--primary),
      30px 0px var(--primary), 30px 10px var(--primary),
      20px 20px var(--primary), 10px 30px var(--primary),
      0px 30px var(--primary), -10px 30px var(--primary), -20px 20px transparent,
      -30px 10px transparent, -30px 0px transparent, -30px -10px transparent,
      -20px -20px transparent, -10px -30px transparent;
  }
  18.75% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px var(--primary), 30px 0px var(--primary),
      30px 10px var(--primary), 20px 20px var(--primary),
      10px 30px var(--primary), 0px 30px var(--primary),
      -10px 30px var(--primary), -20px 20px var(--primary),
      -30px 10px transparent, -30px 0px transparent, -30px -10px transparent,
      -20px -20px transparent, -10px -30px transparent;
  }
  25% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px var(--primary),
      30px 10px var(--primary), 20px 20px var(--primary),
      10px 30px var(--primary), 0px 30px var(--primary),
      -10px 30px var(--primary), -20px 20px var(--primary),
      -30px 10px var(--primary), -30px 0px transparent, -30px -10px transparent,
      -20px -20px transparent, -10px -30px transparent;
  }
  31.25% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px var(--primary), 20px 20px var(--primary),
      10px 30px var(--primary), 0px 30px var(--primary),
      -10px 30px var(--primary), -20px 20px var(--primary),
      -30px 10px var(--primary), -30px 0px var(--primary),
      -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
  }
  37.5% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px var(--primary), 10px 30px var(--primary),
      0px 30px var(--primary), -10px 30px var(--primary),
      -20px 20px var(--primary), -30px 10px var(--primary),
      -30px 0px var(--primary), -30px -10px var(--primary),
      -20px -20px transparent, -10px -30px transparent;
  }
  43.75% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px transparent, 10px 30px var(--primary),
      0px 30px var(--primary), -10px 30px var(--primary),
      -20px 20px var(--primary), -30px 10px var(--primary),
      -30px 0px var(--primary), -30px -10px var(--primary),
      -20px -20px var(--primary), -10px -30px transparent;
  }
  50% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px var(--primary), -10px 30px var(--primary),
      -20px 20px var(--primary), -30px 10px var(--primary),
      -30px 0px var(--primary), -30px -10px var(--primary),
      -20px -20px var(--primary), -10px -30px var(--primary);
  }
  56.25% {
    box-shadow: 0px -30px var(--primary), 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px transparent, -10px 30px var(--primary), -20px 20px var(--primary),
      -30px 10px var(--primary), -30px 0px var(--primary),
      -30px -10px var(--primary), -20px -20px var(--primary),
      -10px -30px var(--primary);
  }
  62.5% {
    box-shadow: 0px -30px var(--primary), 10px -30px var(--primary),
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px transparent, -10px 30px transparent, -20px 20px var(--primary),
      -30px 10px var(--primary), -30px 0px var(--primary),
      -30px -10px var(--primary), -20px -20px var(--primary),
      -10px -30px var(--primary);
  }
  68.75% {
    box-shadow: 0px -30px var(--primary), 10px -30px var(--primary),
      20px -20px var(--primary), 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent,
      -30px 10px var(--primary), -30px 0px var(--primary),
      -30px -10px var(--primary), -20px -20px var(--primary),
      -10px -30px var(--primary);
  }
  75% {
    box-shadow: 0px -30px var(--primary), 10px -30px var(--primary),
      20px -20px var(--primary), 30px -10px var(--primary), 30px 0px transparent,
      30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px transparent, -10px 30px transparent, -20px 20px transparent,
      -30px 10px transparent, -30px 0px var(--primary),
      -30px -10px var(--primary), -20px -20px var(--primary),
      -10px -30px var(--primary);
  }
  81.25% {
    box-shadow: 0px -30px var(--primary), 10px -30px var(--primary),
      20px -20px var(--primary), 30px -10px var(--primary),
      30px 0px var(--primary), 30px 10px transparent, 20px 20px transparent,
      10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
      -20px 20px transparent, -30px 10px transparent, -30px 0px transparent,
      -30px -10px var(--primary), -20px -20px var(--primary),
      -10px -30px var(--primary);
  }
  87.5% {
    box-shadow: 0px -30px var(--primary), 10px -30px var(--primary),
      20px -20px var(--primary), 30px -10px var(--primary),
      30px 0px var(--primary), 30px 10px var(--primary), 20px 20px transparent,
      10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
      -20px 20px transparent, -30px 10px transparent, -30px 0px transparent,
      -30px -10px transparent, -20px -20px var(--primary),
      -10px -30px var(--primary);
  }
  93.75% {
    box-shadow: 0px -30px var(--primary), 10px -30px var(--primary),
      20px -20px var(--primary), 30px -10px var(--primary),
      30px 0px var(--primary), 30px 10px var(--primary),
      20px 20px var(--primary), 10px 30px transparent, 0px 30px transparent,
      -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px var(--primary);
  }
  100% {
    box-shadow: 0px -30px var(--primary), 10px -30px var(--primary),
      20px -20px var(--primary), 30px -10px var(--primary),
      30px 0px var(--primary), 30px 10px var(--primary),
      20px 20px var(--primary), 10px 30px var(--primary), 0px 30px transparent,
      -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }
}

/* DEMO */
