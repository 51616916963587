@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply border-gray-400 rounded-lg shadow-sm focus:border-secondary-200 focus:ring-secondary-100;
  }
  [type="checkbox"],
  [type="radio"] {
    @apply border-gray-400 rounded text-secondary-200;
  }

  input:disabled {
    @apply border-gray-300 ring-gray-300 text-gray-400;
  }
}

@layer components {
  .card {
    @apply bg-white rounded overflow-hidden shadow-md relative;
  }

  .badge {
    @apply bg-secondary-100 text-textSecondary text-xs uppercase font-bold rounded-full absolute;
  }

  .btn {
    @apply rounded text-secondary-200 py-2 px-3.5 font-bold cursor-pointer tracking-wider transition-colors border border-secondary-200 hover:bg-gray-50;
  }

  .btn-primary {
    @apply rounded py-2 px-3.5 font-bold cursor-pointer tracking-wider transition-colors bg-primary-100 text-white relative overflow-hidden;
  }

  .btn-secondary {
    @apply rounded py-2 px-3.5 font-bold cursor-pointer tracking-wider transition-colors bg-secondary-100 text-white relative overflow-hidden;
  }
}

.animate-dialog-background {
  animation: animate_dialog 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes animate_dialog {
  0% {
    opacity: 0;
    /* transform: scale(0.5);
    border-radius: 100%; */
  }
  100% {
    opacity: 1;
    /* transform: scale(1);
    border-radius: none; */
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.3em;
}
.custom-scrollbar::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-secondary-100 rounded-[100px] pr-1;
}

.live-ping {
  /* @apply bg-green-400 rounded-full border-[50px] border-green-200 */
  background: rgb(74 222 128);
  border-radius: 50%;
  border: 50px solid rgb(187 247 208);
  width: 20px;
  height: 20px;
  animation: load 1.5s ease-out infinite;
}

@keyframes load {
  0% {
    background: rgb(74 222 128);
    border: 0px solid #fff;
  }
  50% {
    background: rgb(74 222 128);
    border: 100px solid rgb(187 247 208);
  }
  100% {
    background: rgb(74 222 128);
    border: 0px solid rgb(187 247 208);
  }
}
